<template>
  <div>
    <span>
      <slot>{{ user.lastName }}</slot>
    </span>
  </div>
</template>
<script>
  export default{
    data(){
      return{
        test:"abc",
        user:{lastName:"abc"}
      }
    },

  }
</script>